/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-sequences */
// import strings from '../../localization';
import { motion } from 'framer-motion/dist/framer-motion';
import { scroller } from 'react-scroll';
import { Link, useLocation } from 'wouter';
import { setLang } from '../../libs/storage';
import strings from '../../localization';
import CloseIcon from '../../assets/icon-close.svg';
import FranceFlag from '../../assets/france.png';
import UkFlag from '../../assets/unitedKingdom.png';
import SpainFlag from '../../assets/spanish.png';

const Menu = ({ toggle, isOpen }) => {
  const menuAnim = { open: { opacity: 1, x: 0 }, closed: { opacity: 0, x: '-100%' } };
  const scrollTarget = (target) => scroller.scrollTo(target, { smooth: true, duration: 700 });
  const [location, setLocation] = useLocation();

  const scrollToPage = async (target) => {
    if (location !== '/') {
      await setLocation('/');
    }
    scrollTarget(target);
  };
  return (
    <motion.div
      animate={isOpen ? 'open' : 'closed'}
      variants={menuAnim}
      transition={{ duration: 0.01 }}
      initial="closed"
      className="menu"
    >
      <div className="menu-layout" style={{ paddingTop: '10%' }}>
        <button
          type="button"
          className="nav-button position-relative abs-center"
          onClick={toggle}
          style={{ width: 60, height: 60, left: 'calc(100% - 1rem)' }}
        >
          <img style={{ width: '25px', height: '25px' }} className="abs-center" alt="menu" src={CloseIcon} width={30} />
        </button>
        <div
          className="menu-link"
          onClick={() => (scrollToPage('about'), toggle())}
        >
          <p className="menu-text">{strings['menu-element-1']}</p>
        </div>
        <div
          className="menu-link"
          onClick={() => (scrollToPage('specs'), toggle())}
        >
          <p className="menu-text">{strings['menu-element-2']}</p>
        </div>
        <div
          className="menu-link"
          onClick={() => (setLocation('/mint'), toggle())}
        >
          <p className="menu-text">{strings['menu-element-3']}</p>
        </div>
        <div
          className="menu-link"
          onClick={() => (scrollToPage('roadmap'), toggle())}
        >
          <p className="menu-text">{strings['menu-element-4']}</p>
        </div>
        <div
          className="menu-link"
          onClick={() => (scrollToPage('benefits'), toggle())}
        >
          <p className="menu-text">{strings['menu-element-5']}</p>
        </div>
        <section>
          <div style={{ paddingTop: '60px' }}>
            <a href="https://www.instagram.com/ballerwhalenft/" target="_blank" rel="noreferrer">
              <button type="button" className="position-relative nav-button mr-4" style={{ width: 50 }}>
                <i style={{ fontSize: '24px' }} className="fa fa-instagram text-light abs-center" />
              </button>
            </a>
            <a href="https://twitter.com/BallerwhaleNFT" target="_blank" rel="noreferrer">
              <button type="button" className="position-relative nav-button ml-2 mr-4" style={{ width: 50 }}>
                <i style={{ fontSize: '24px' }} className="fa fa-twitter text-light abs-center" />
              </button>
            </a>
            <a href="mailto:contact@ballerwhalenft.com" target="_blank" aria-label="Email" rel="noreferrer">
              <button type="button" className="position-relative nav-button ml-2" style={{ width: 50 }}>
                <i style={{ fontSize: '20px', marginTop: '-1px' }} className="fa fa-envelope text-light abs-center" />
              </button>
            </a>
          </div>
          <p style={{ color: '#989898', paddingTop: '40px' }}>
            BallerWhale
            {' '}
            {new Date().getFullYear()}
            {' '}
            {strings['menu-copyright']}
            {' '}
          </p>
          <Link style={{ color: '#989898' }} onClick={() => toggle()} to="/terms">{strings['menu-terms']}</Link>
        </section>
        <div className="position-relative">
          <button onClick={() => setLang('en')} className="language-button" type="button"><img className="language-image" alt="flag" src={UkFlag} /></button>
          <button onClick={() => setLang('fr')} className="language-button ml-4" type="button"><img className="language-image" alt="flag" src={FranceFlag} /></button>
          <button onClick={() => setLang('esp')} className="language-button ml-4" type="button"><img className="language-image" alt="flag" src={SpainFlag} /></button>
        </div>
      </div>
    </motion.div>
  );
};
export default Menu;
