/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from 'react-hook-form';

const Pause = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm();
  return (
    <form className="card p-4 mb-4" onSubmit={handleSubmit(onSubmit)}>
      <h4>Change Contract State</h4>
      <div className="form-group">
        <div className="form-check">
          <input {...register('state')} type="checkbox" className="form-check-input" />
          <label className="form-check-label">Is contract paused?</label>
        </div>
      </div>
      <button type="submit" className="btn btn-primary">Set State</button>
    </form>
  );
};

export default Pause;
