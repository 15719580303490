import { motion } from 'framer-motion/dist/framer-motion';
import strings from '../../../localization';

const RoadMapSegment = ({
  first, content, image, invert, bg = 'active', anim, last, dark,
}) => (
  <div className="roadmap" style={{ flexDirection: invert ? 'row-reverse' : 'row' }}>
    <div className={invert ? 'justify-content-end' : 'justify-content-start'}>
      <p style={{ padding: '0', color: dark ? '#061F89' : '#fff' }} className={`roadmap-box roadmap-text-${bg}`}>
        {content}
      </p>
    </div>
    <section>
      {first && <img className="abs-center" style={{ top: 0 }} src="assets/roadmap/check.svg" alt="check" />}
      <i className="abs-center fa fa-plus" style={{ top: '33%', color: 'white', fontSize: 32 }} />
    </section>
    <div className={invert ? 'justify-content-start' : 'justify-content-end'}>
      <motion.img
        className={`roadmap-fish-${last ? '2' : '1'} roadmap-padding-${invert ? '1' : '2'}`}
        initial={{ x: 0, y: 0, rotate: 0 }}
        animate={anim}
        transition={{ duration: 9, yoyo: Infinity }}
        alt=""
        src={image}
      />
    </div>
  </div>
);

const RoadMapSegmentMobile = ({
  first, content, image, invert, bg = 'active', anim, last,
}) => (
  <div className="roadmap" style={{ flexDirection: invert ? 'row-reverse' : 'row' }}>
    <div className="flex-column">
      <div className={invert ? 'justify-content-start' : 'justify-content-end'}>
        <motion.img
          className={`roadmap-fish-${last ? '2' : '1'} roadmap-padding-${invert ? '1' : '2'}`}
          initial={{ x: 0, y: 0, rotate: 0 }}
          animate={anim}
          transition={{ duration: 9, yoyo: Infinity }}
          alt=""
          src={image}
        />
      </div>
      <p style={{ padding: '0' }} className={`roadmap-box roadmap-text-${bg}`}>
        {content}
      </p>
    </div>
    <section>
      {first && <img className="abs-center" style={{ top: 0 }} src="assets/roadmap/check.svg" alt="check" />}
      <i className="abs-center fa fa-plus" style={{ top: first ? '45%' : '66%', color: 'white', fontSize: 32 }} />
    </section>
  </div>
);

const Roadmap = () => (
  <div style={{ overflowX: 'hidden' }}>
    {window.innerWidth > 768 ? (
      <div className="container">
        <h1 className="header text-light text-center mb-5">{strings['roadmap-heading']}</h1>
        <RoadMapSegment anim={{ x: [3, 15, -2, -7, 1], y: [4, -3, 8, -5, 2], rotate: [1, -5, 20, -14] }} bg="1" content={strings['roadmap-1']} image="assets/roadmap/fish-1.svg" first dark />
        <RoadMapSegment anim={{ x: [14, 4, 10, -1, -5], y: [5, -10, 2, -5, 3], rotate: [-23, -5, 14, 1] }} bg="2" content={strings['roadmap-2']} image="assets/roadmap/fish-2.svg" invert />
        <RoadMapSegment anim={{ x: [-2, -8, 5, 11, -7], y: [-3, -14, 3, 7, 10], rotate: [10, -20, 1, -2] }} bg="4" content={strings['roadmap-3']} image="assets/roadmap/fish-3.svg" />
        <RoadMapSegment anim={{ x: [-14, 2, 12, 3, -8], y: [-11, -15, -4, 5, 11], rotate: [24, 4, -12, 18] }} bg="3" content={strings['roadmap-4']} image="assets/roadmap/fish-4.svg" invert />
        <RoadMapSegment anim={{ x: [20, 10, -20, 15, -5], y: [5, -10, 21, -5, 3], rotate: [1, -5, 20, -5] }} bg="3" content={strings['roadmap-5']} image="assets/roadmap/fish-5.svg" />
        <RoadMapSegment anim={{ x: [20, 10, -20, 15, -5], y: [-4, -10, 2, 11, 3], rotate: [3, -5, 23, -13] }} bg="3" content={strings['roadmap-6']} image="assets/roadmap/fish-6.svg" invert />
        <RoadMapSegment anim={{ x: [12, 3, -15, 1, -10], y: [11, -2, 20, -5, 12], rotate: [1, -5, -10, 14] }} bg="4" content={strings['roadmap-7']} last />
      </div>
    ) : (
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <h1 className="header text-light text-center mb-5">{strings['roadmap-heading']}</h1>
        <RoadMapSegmentMobile anim={{ x: [3, 15, -2, -7, 1], y: [4, -3, 8, -5, 2], rotate: [1, -5, 20, -14] }} bg="1" content={strings['roadmap-1']} invert first last />
        <RoadMapSegmentMobile anim={{ x: [14, 4, 10, -1, -5], y: [5, -10, 2, -5, 3], rotate: [-23, -5, 14, 1] }} bg="2" content={strings['roadmap-2']} image="assets/roadmap/fish-1.svg" invert />
        <RoadMapSegmentMobile anim={{ x: [-2, -8, 5, 11, -7], y: [-3, -14, 3, 7, 10], rotate: [10, -20, 1, -2] }} bg="4" content={strings['roadmap-3']} image="assets/roadmap/fish-2.svg" invert />
        <RoadMapSegmentMobile anim={{ x: [-14, 2, 12, 3, -8], y: [-11, -15, -4, 5, 11], rotate: [24, 4, -12, 18] }} bg="3" content={strings['roadmap-4']} image="assets/roadmap/fish-3.svg" invert />
        <RoadMapSegmentMobile anim={{ x: [20, 10, -20, 15, -5], y: [5, -10, 21, -5, 3], rotate: [1, -5, 20, -5] }} bg="3" content={strings['roadmap-5']} image="assets/roadmap/fish-4.svg" invert />
        <RoadMapSegmentMobile anim={{ x: [20, 10, -20, 15, -5], y: [-4, -10, 2, 11, 3], rotate: [3, -5, 23, -13] }} bg="3" content={strings['roadmap-6']} image="assets/roadmap/fish-5.svg" invert />
        <RoadMapSegmentMobile anim={{ x: [12, 3, -15, 1, -10], y: [11, -2, 20, -5, 12], rotate: [1, -5, -10, 14] }} bg="3" content={strings['roadmap-7']} image="assets/roadmap/fish-6.svg" invert />
      </div>
    )}

  </div>
);

export default Roadmap;
