import { motion } from 'framer-motion/dist/framer-motion';
import strings from '../../../localization';

const Specs = () => (
  <section id="specs" className="container">
    {window.innerWidth > 768 ? (
      <div>
        <h1 style={{ paddingBottom: '20px' }} className="display-3 text-center text-light header">{strings['specs-title']}</h1>
        <h2 style={{ paddingBottom: '100px' }} className="text-center text-light specs-desc">{strings['specs-subtitle']}</h2>
        <section className="d-flex flex-row">
          <section className="d-flex flex-column justify-content-start align-items-start fl-1">
            <p className="w-75 text-light">{strings['specs-left-desc']}</p>
            <div className="d-flex flex-grow-1 align-items-end">
              <motion.img className="specs-fish" initial={{ x: 0, y: 0 }} animate={{ x: [6, 2, -4, 6, 2], y: [3, -5, 3, -7, 1], rotate: [10, 15, 30, -12, -15] }} transition={{ duration: 8, yoyo: Infinity }} alt="fish" src="assets/specs/fish-group.svg" />
            </div>
          </section>
          <section>
            <img
              src="assets/specs/specs-image-1.png"
              alt="whale"
              className="specs-white-whale specs-img"
            />
          </section>
          <section className="d-flex flex-column justify-content-end align-items-end fl-1">
            <div className="d-flex flex-grow-1 align-items-start">
              <motion.img className="specs-fish" initial={{ x: 0, y: 0 }} animate={{ x: [-4, 5, -1, -3, -8], y: [-2, -7, 8, 2, -1], rotate: [-20, -10, 3, 15, -6] }} transition={{ duration: 10, yoyo: Infinity }} alt="fish" src="assets/specs/fish-group.svg" />
            </div>
            <p className="w-75 text-light text-right">{strings['specs-right-desc']}</p>
          </section>
        </section>
      </div>
    ) : (
      <div>
        <section style={{ marginLeft: 'auto', marginRight: 'auto' }} className="d-flex flex-column col-md-6">
          <section className="d-flex flex-column justify-content-center align-items-center fl-1">
            <h1 style={{ paddingBottom: '20px' }} className="display-3 text-center text-light header">{strings['specs-title']}</h1>
            <h2 style={{ paddingBottom: '50px' }} className="text-center text-light specs-desc">{strings['specs-subtitle']}</h2>
            <section>
              <img
                src="assets/specs/specs-image-1.png"
                alt="whale"
                className="specs-white-whale specs-img"
              />
            </section>
            <section style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: '50px' }}>
              <p className="text-light">{strings['specs-left-desc']}</p>
              <p className="text-light">{strings['specs-right-desc']}</p>
            </section>
          </section>
        </section>
      </div>
    )}
  </section>
);

export default Specs;
