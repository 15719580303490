import React from 'react';
import ReactDOM from 'react-dom';
import { MetaMaskProvider } from 'metamask-react';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { App } from './screens';
// import { getToken } from './libs/storage';
import './index.css';
import './design.css';

const app = initializeApp({
  apiKey: 'AIzaSyANa52E9prMdwUBrjjNUQ_cw-nSvQyz4K0',
  authDomain: 'ballerwhale-6376a.firebaseapp.com',
  projectId: 'ballerwhale-6376a',
  storageBucket: 'ballerwhale-6376a.appspot.com',
  messagingSenderId: '937052137967',
  appId: '1:937052137967:web:9f1e739289fa74c872ee1a',
  measurementId: 'G-TTDHYFXCTF',
});

getAnalytics(app);

ReactDOM.render(
  <MetaMaskProvider>
    <App />
  </MetaMaskProvider>,
  document.getElementById('root'),
);
