import { motion } from 'framer-motion/dist/framer-motion';
import strings from '../../../localization';

const Mentorship = () => (
  <div id="mentorship" className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <h1 className="text-light header">{strings['mentorship-title']}</h1>
      </div>
      <div className="col-sm-12 text-center">
        <p className="text-light">{strings['mentorship-subtitle']}</p>
      </div>
      <div className="col-sm-12 col-md-2 position-relative">
        {window.innerWidth > 768 && (
        <motion.img
          style={{ maxWidth: '90%' }}
          initial={{ x: 0, y: 0 }}
          animate={{ x: [2, 5, -20, -4, 12], y: [7, -15, 14, -5, -23], rotate: [20, -5, 360, -20] }}
          transition={{ duration: 12, yoyo: Infinity }}
          className="abs-center no-mobile"
          src="assets/mentorship/star.png"
          alt="star"
        />
        )}
      </div>
      <div
        className="col-sm-4 col-md-8 text-center"
        style={{
          padding: '0',
        }}
      >
        <p className="mentorship-text">{strings['mentorship-text-1']}</p>
      </div>
      <div className="col-sm-4 col-md-2 position-relative">
        {window.innerWidth > 768 && (
        <motion.img
          style={{ maxWidth: '70%' }}
          initial={{ x: 0, y: 0, rotate: 40 }}
          animate={{ x: [20, 10, -20, 15, -5], y: [5, -10, 2, -5, 3], rotate: [1, -5, 20, -30] }}
          transition={{ duration: 9, yoyo: Infinity }}
          className="abs-center"
          src="assets/mentorship/star.png"
          alt="star"
        />
        )}
      </div>
      <div className="col-sm-4 p-2 no-mobile">
        <p
          className="mentorship-text-2"
          style={{
            padding: '0',
          }}
        >
          {strings['mentorship-text-2']}
        </p>
      </div>
      <div className="col-sm-4">
        <img className="w-100" src="assets/mentorship/mentorship-image-1.svg" alt="baby" />
        {window.innerWidth > 768 && (
        <motion.img // Bottom Star 1
          initial={{ x: 0, y: 0 }}
          animate={{ x: [1, -15, -4, 4, -9], y: [32, -24, -13, -5, 1], rotate: [30, 4, 14, -1] }}
          transition={{ duration: 8, yoyo: Infinity }}
          src="assets/mentorship/star.png"
          alt="star"
          style={{
            bottom: '-5rem',
            left: '-3rem',
            position: 'absolute',
            maxWidth: '20%',
          }}
        />
        )}
        {window.innerWidth > 768 && (
        <motion.img // Bottom Star 2
          initial={{ x: 0, y: 0, rotate: 20 }}
          animate={{
            x: [1, 5, -12, -15, 12],
            y: [12, -6, 4, -12, -2],
            rotate: [270, -1, 5, -2, 20],
          }}
          transition={{ duration: 10, yoyo: Infinity }}
          src="assets/mentorship/star.png"
          alt="star"
          style={{
            bottom: '-8rem',
            right: '-2.5rem',
            position: 'absolute',
            maxWidth: '80%',
          }}
        />
        )}
      </div>
      <div className="col-sm-4 p-2 no-mobile">
        <p
          className="mentorship-text-2"
          style={{
            padding: '0',
          }}
        >
          {strings['mentorship-text-3']}
        </p>
      </div>
    </div>
  </div>
);

export default Mentorship;
