// Local storage based authentication.
const setToken = (token) => { localStorage.setItem('token', token); window.location.reload(); };
const getToken = () => localStorage.getItem('token');

// Local storage based localization.
const setLang = (lang) => { localStorage.setItem('lang', lang); window.location.reload(); };
const getLang = () => localStorage.getItem('lang');

export {
  setToken, getToken, setLang, getLang,
};
