/* eslint-disable max-len */
import { useMetaMask } from 'metamask-react';
import Web3 from 'web3-eth-contract';

import abis from '../../../constants/abis.json';
import { contracts } from '../../../constants/blockchain.json';

import Pause from './Common/Pause';
import MintTo from './Common/MintTo';
import BaseURI from './Common/BaseURI';
import Withdraw from './Common/Withdraw';
import MaxSupply from './Common/MaxSupply';

// BabyBallerWhales
import IncreaseSupply from './BabyBallerWhale/IncreaseSupply';
import ToggleFirst from './BabyBallerWhale/ToggleFirst';
import TogglePaid from './BabyBallerWhale/TogglePaid';

const Admin = () => {
  const { account, ethereum } = useMetaMask(); Web3.setProvider(ethereum);
  const { methods: ballerwhale } = new Web3(abis.main, contracts.main);
  const { methods: babyballerwhale } = new Web3(abis.promotion, contracts.promotion);

  return (
    <main
      className="d-flex flex-column align-items-center"
      style={{ paddingTop: '10rem', minHeight: '100vh' }}
    >
      {account ? (
        <>
          <details style={{ width: '45%' }}>
            <summary className="h1 text-light">BallerWhales Contract</summary>
            {/* Empty */}
            <Pause onSubmit={(form) => ballerwhale.pause(form.state)
              .estimateGas({ from: account, to: contracts.main })
              .then((gasEstimate) => ballerwhale.pause(form.state)
                .send({ from: account, to: contracts.main, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            {/* Empty */}
            <MintTo onSubmit={(form) => ballerwhale.mintForOwner(form.address, form.amount)
              .estimateGas({ from: account, to: contracts.main })
              .then((gasEstimate) => ballerwhale.mintForOwner(form.address, form.amount)
                .send({ from: account, to: contracts.main, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <BaseURI onSubmit={(form) => ballerwhale.setBaseURI(form.uri)
              .estimateGas({ from: account, to: contracts.main })
              .then((gasEstimate) => ballerwhale.setBaseURI(form.uri)
                .send({ from: account, to: contracts.main, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <Withdraw onSubmit={() => ballerwhale.withdraw()
              .estimateGas({ from: account, to: contracts.main })
              .then((gasEstimate) => ballerwhale.withdraw()
                .send({ from: account, to: contracts.main, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <MaxSupply onSubmit={(form) => ballerwhale.setMaxSupply(form.supply)
              .estimateGas({ from: account, to: contracts.main })
              .then((gasEstimate) => ballerwhale.setMaxSupply(form.supply)
                .send({ from: account, to: contracts.main, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
          </details>
          <details style={{ width: '45%' }}>
            <summary className="h1 text-light">BabyBallerWhales Contract</summary>
            <Pause onSubmit={(form) => babyballerwhale.setPaused(form.pause)
              .estimateGas({ from: account, to: contracts.promotion })
              .then((gasEstimate) => babyballerwhale.setPaused(form.pause)
                .send({ from: account, to: contracts.promotion, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <BaseURI onSubmit={(form) => babyballerwhale.setUriPrefix(form.uri)
              .estimateGas({ from: account, to: contracts.promotion })
              .then((gasEstimate) => babyballerwhale.setUriPrefix(form.uri)
                .send({ from: account, to: contracts.promotion, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <Withdraw onSubmit={() => babyballerwhale.withdraw()
              .estimateGas({ from: account, to: contracts.promotion })
              .then((gasEstimate) => babyballerwhale.withdraw()
                .send({ from: account, to: contracts.promotion, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <MaxSupply onSubmit={(form) => babyballerwhale.setMaxSupply(form.supply)
              .estimateGas({ from: account, to: contracts.promotion })
              .then((gasEstimate) => babyballerwhale.setMaxSupply(form.supply)
                .send({ from: account, to: contracts.promotion, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <IncreaseSupply onSubmit={(form) => babyballerwhale.mintIncreaseSupply(form.amount, form.startID)
              .estimateGas({ from: account, to: contracts.promotion })
              .then((gasEstimate) => babyballerwhale.mintIncreaseSupply(form.amount, form.startID)
                .send({ from: account, to: contracts.promotion, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <ToggleFirst onSubmit={(form) => babyballerwhale.toggleFirstStage(form.state)
              .estimateGas({ from: account, to: contracts.promotion })
              .then((gasEstimate) => babyballerwhale.toggleFirstStage(form.state)
                .send({ from: account, to: contracts.promotion, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
            <TogglePaid onSubmit={(form) => babyballerwhale.togglePaidMint(form.state)
              .estimateGas({ from: account, to: contracts.promotion })
              .then((gasEstimate) => babyballerwhale.togglePaidMint(form.state)
                .send({ from: account, to: contracts.promotion, gas: gasEstimate + 100000 })
                .then(() => { alert('Successfull'); }))}
            />
          </details>
        </>
      ) : <h1>Loading...</h1>}
    </main>
  );
};

export default Admin;
