/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useMetaMask } from 'metamask-react';
import Web3 from 'web3-eth-contract';
import ReactPaginate from 'react-paginate';
import web3 from 'web3';
import ScrollToTop from '../../../utils/ScrollToTop';
import IconLeft from '../../../assets/arrow-left.svg';
import IconRight from '../../../assets/arrow-right.svg';

import { PagedGallery } from '../../../components/widgets/galleries';

import abis from '../../../constants/abis.json';
import { contracts } from '../../../constants/blockchain.json';
import { ballerwhale } from '../../../constants/collections.json';
import strings from '../../../localization';

const showInPage = 16;

const Gallery = () => {
  const { account, ethereum, chainId } = useMetaMask(); Web3.setProvider(ethereum);
  const { methods: { walletOfOwner } } = new Web3(abis.main, contracts.main);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [availableTokens, setAvailableTokens] = useState([]);

  const CallWalletOfOwner = () => walletOfOwner(ballerwhale.owner).call().then((owned) => { setAvailableTokens(owned); setTotalPage(Math.ceil(owned.length / showInPage)); });

  useEffect(() => account && CallWalletOfOwner(), [account]);

  useEffect(async () => {
    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(137) }],
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Polygon Mainnet',
                chainId: web3.utils.toHex(137),
                nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                rpcUrls: ['https://polygon-rpc.com/'],
              },
            ],
          });
        }
      }
    }
    console.log(chainId);
  }, [chainId]);

  return (
    <>
      <ScrollToTop />
      <div className="container container-padding" style={{ paddingBottom: account ? '200px' : '150px' }}>
        <div className="row">
          <h1 className="w-100 text-light text-center">{strings['baller-gallery-header']}</h1>
          <h4
            style={{ fontFamily: 'monospace' }}
            className="w-100 text-light text-center mb-4"
          >
            {strings['baller-gallery-desc']}
          </h4>
        </div>
        {account ? (
          <>
            {chainId !== '0x89' && (
            <>
              <h1 style={{
                color: '#fff', marginTop: '100px', textAlign: 'center', fontSize: '24px',
              }}
              >
                Please switch to Polygon network and refresh the page
              </h1>
            </>
            )}
            <PagedGallery
              cid="bafybeibryzemxrdxfkhtfyxyvxcwtd56oedzpenfiq5wnjimhpsbc4u4zq"
              provider="testnets.opensea.io/assets/mumbai"
              gateway="ipfs.infura-ipfs.io"
              suffix="png"
              subroute="thumbnails"
              analyticsParam="bw_interest"
              indexArray={availableTokens.map((i) => Number(i))}
              contract={contracts.main}
              steps={showInPage}
              page={currentPage}
              onSelect={(i) => window.open(`https://opensea.io/assets/matic/${contracts.main}/${i}`)}
            />
            <div className="row justify-content-center align-items-center pagination mt-4" style={{ paddingTop: '2rem' }}>
              <ReactPaginate
                breakLabel="..."
                onPageChange={(e) => setCurrentPage(e.selected)}
                marginPagesDisplayed={window.innerWidth > 768 ? 2 : 0}
                pageRangeDisplayed={window.innerWidth > 768 ? 3 : 1}
                pageCount={totalPage}
                nextLabel={(
                  <button className="pagination-button" type="button">
                    {strings['gallery-next']}
                    <img alt="next" src={IconRight} className="ml-2" />
                  </button>
                )}
                previousLabel={(
                  <button className="pagination-button" type="button">
                    <img alt="back" src={IconLeft} className="mr-2" />
                    {strings['gallery-back']}
                  </button>
                )}
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        ) : (
          <div className="m-0 p-4 please-connect">
            <h1 className="m-0 p-0 text-center">{strings['msg-metamask-please']}</h1>
            <p className="text-center mb-0 mt-2">{strings['msg-metamask-please-desc']}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
