/* eslint-disable no-unused-vars */
import { Switch, Route, Redirect } from 'wouter';

import dayjs from 'dayjs';
import { useMetaMask } from 'metamask-react';

import { Navbar, Footer } from '../../components/navigation';
import { Overlay } from '../../components/utility';
import admins from '../../constants/admins.json';
import strings from '../../localization';

import Landing from './Landing';
import GalleryPolygon from './Gallery';
import GalleryEth from './GalleryEth';
import GalleryBaller from './GalleryBaller';
import Admin from './Admin';
import Mint from './Mint';
import Terms from './Terms';
import Whale from './BallerWhale';
import Minteth from './Mint/mintEth';
import Mintpoly from './Mint/mintPolygon';

dayjs.locale('en');

const App = () => {
  const { account, chainId } = useMetaMask();
  return (
    <>
      {account && chainId && (parseInt(chainId, 16) !== 137 && parseInt(chainId, 16) !== 1) && (
      <Overlay>
        <h1>{strings['msg-wrong-chain-title']}</h1>
        <p>{strings['msg-wrong-chain-desc']}</p>
      </Overlay>
      )}
      <Navbar />
      <Switch>
        <Route path="/" component={Landing} />
        <Route path="/gallery/polygon" component={GalleryPolygon} />
        <Route path="/gallery/ethereum" component={GalleryEth} />
        <Route path="/galleryballer" component={GalleryBaller} />
        <Route path="/mint" component={Mint} />
        <Route path="/mint/polygon" component={Mintpoly} />
        <Route path="/mint/ethereum" component={Minteth} />
        <Route path="/terms" component={Terms} />
        <Route path="/whale" component={Whale} />
        {/* Double layer security */}
        {admins.includes(account) && <Route path="/admin" component={Admin} />}
        <Redirect to="/" />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
