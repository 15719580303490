import { getAnalytics, logEvent } from 'firebase/analytics';

const PagedGallery = ({
  cid,
  gateway, // pinata, cloudflare etc.
  contract,
  provider = 'opensea.io/assets',
  steps = 10,
  page = 0,
  suffix = 'png',
  indexArray,
  countFromOne = false,
  onSelect,
  analyticsParam = 'unset_gallery_click',
  collection = '',
  subroute = '',
}) => {
  const analytics = getAnalytics();
  return (
    <section
      className="async-gallery grid grid-4 flex-grow-1"
    >
      {indexArray.slice(page * steps, (page + 1) * steps).map((i) => (
        <button
          type="button"
          onClick={() => {
            logEvent(analytics, analyticsParam, { nft_id: i });
            onSelect(i);
          }}
          key={`nft${i + countFromOne}`}
          style={{
            backgroundImage: `url(https://${cid}.${gateway}/${subroute}/${i}.${suffix})`,
          }}
          alt="nft-1"
          href={`https://${provider}/${contract}/${(page * steps) + (i + countFromOne)}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="abs-center" />
          <h4 className="text-left w-100">{`#${i}\n${collection}`}</h4>
          {/* <h4>{owned ? 'Owned' : 'Buy at OpenSea.io'}</h4> */}
        </button>
      ))}
    </section>
  );
};

export default PagedGallery;
