import React from 'react';
import strings from '../../../localization';
import ScrollToTop from '../../../utils/ScrollToTop';

const index = () => (
  <section>
    <ScrollToTop />
    <div className="container text-light container-padding" style={{ marginBottom: '200px' }}>
      <div className="row">
        <div className="col-md-12" style={{ paddingBottom: '50px' }}>
          <h1 className="text-center">{strings['terms-heading']}</h1>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <p>{strings['terms-description']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-1-heading']}</h5>
          <p>{strings['terms-1-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-2-heading']}</h5>
          <p>{strings['terms-2-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-3-heading']}</h5>
          <p>{strings['terms-3-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-4-heading']}</h5>
          <p>{strings['terms-4-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-5-heading']}</h5>
          <p>{strings['terms-5-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-6-heading']}</h5>
          <p>{strings['terms-6-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-7-heading']}</h5>
          <p>{strings['terms-7-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-8-heading']}</h5>
          <p>{strings['terms-8-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-9-heading']}</h5>
          <p>{strings['terms-9-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-10-heading']}</h5>
          <p>{strings['terms-10-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-11-heading']}</h5>
          <p>{strings['terms-11-subtitle']}</p>
        </div>
        <div className="col-md-12" style={{ paddingBottom: '10px' }}>
          <h5>{strings['terms-12-heading']}</h5>
          <p>{strings['terms-12-subtitle']}</p>
        </div>
      </div>
    </div>
  </section>
);

export default index;
