/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import { useMetaMask } from 'metamask-react';
import { useEffect, useState } from 'react';
import Web3 from 'web3-eth-contract';
import { Link } from 'wouter';

import abis from '../../../constants/abis.json';
import { contracts } from '../../../constants/blockchain.json';
import { ParseRPCError } from '../../../utils/string';
import strings from '../../../localization';
import collections from '../../../constants/collections.json';

const alias = {
  1: 'ethereum',
  137: 'polygon',
};

const Mint = () => {
  const { account, ethereum, chainId } = useMetaMask(); Web3.setProvider(ethereum);
  const {
    main: to, methods: {
      getMintedTokens, mintPaid, totalSupply,
    },
  } = new Web3(abis.ethPromotion, contracts.promotionEth);

  const [mintableTokens, setMintableTokens] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentSupply, setCurrentSupply] = useState(0);
  const MAX_SUPPLY = 3000;

  const CallCurrentSupply = () => totalSupply().call().then((current) => setCurrentSupply(current));

  const GetAvailableNFTs = () => getMintedTokens().call().then((minted) => {
    setMintableTokens(
      Array.from(Array(Number(MAX_SUPPLY)).keys()).map((i) => i + 1).filter(
        function (e) { return this.indexOf(e) < 0; },
        minted.map((i) => Number(i)),
      ),
    );
  });

  useEffect(() => { if (account) { GetAvailableNFTs(); CallCurrentSupply(); } }, [account]);
  useEffect(() => currentSupply && setFeedback(`${MAX_SUPPLY - currentSupply} ${strings['mint-count']}`), [currentSupply]);

  return (
    <>
      <div className="container d-flex flex-column align-items-center container-padding" style={{ paddingBottom: account ? '200px' : '150px' }}>
        <h1 className="text-light text-center mb-2">{strings['mint-header']}</h1>
        <div className="mint-container" style={{ marginTop: '64px' }}>
          <div className="mint-container-top position-relative pt-4">
            <h3 style={{ fontFamily: 'monospace' }} className="text-light text-center">{strings['mint-desc']}</h3>
            <img className="abs-center" style={{ width: '15rem', top: '105%' }} alt="bubble" src="../assets/mint/bubble-1.png" />
          </div>
          <div className="d-flex flex-column align-items-center mt-2">
            <div style={{ paddingTop: '6rem' }} className="d-flex justify-content-center">
              <p style={{ maxWidth: '80%' }} className="text-light text-center">
                {strings['mint-text']}
              </p>
            </div>
            <p className="text-light text-center">
              {strings['mint-price-eth']}
            </p>
            <p className="text-light text-center">{loading ? `${strings['mint-state']}` : feedback}</p>
            <button
              type="button"
              onClick={() => {
                const index = Math.floor(Math.random() * mintableTokens.length);
                setLoading(true);
                mintPaid(mintableTokens[index]).estimateGas({ from: account, to, value: collections.babyballerwhaleEth.publicSalePriceWei })
                  .then((gasEstimate) => mintPaid(mintableTokens[index]).send({
                    from: account, to, gas: gasEstimate + 100000, value: collections.babyballerwhaleEth.publicSalePriceWei,
                  })
                    .then(() => { setFeedback(`${strings['mint-success']}`); })
                    .catch((error) => { setFeedback(ParseRPCError(error)); }))
                  .catch(() => { setFeedback(`${strings['mint-failed']}`); })
                  .finally(() => { GetAvailableNFTs(); setLoading(false); });
              }}
              className="mint-button text-light mb-2 mt-2"
              disabled={loading}
            >
              {strings['mint-gallery-random-button']}
            </button>
            <Link to="/gallery/ethereum">
              <button
                {...{ loading }}
                type="button"
                className="mint-button text-light"
                disabled={loading}
              >
                {strings['mint-gallery-button']}
              </button>
            </Link>
          </div>
          <p style={{
            color: 'white',
            textAlign: 'center',
            marginTop: '1rem',
            maxWidth: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          />
        </div>
        {/* <div className="m-0 p-4 please-connect">
              <h1 className="m-0 p-0 text-center">{strings['mint-soon']}</h1>
            </div> */}
      </div>
    </>
  );
};
export default Mint;
