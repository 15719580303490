/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { animateScroll as scroll, scroller } from 'react-scroll';
import { useLocation } from 'wouter';
import strings from '../../localization';
import Logo from '../../assets/footer-logo-1.png';
import FooterImage from '../../assets/footer-image-1.svg';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const [location, setLocation] = useLocation();
  const scrollTarget = (target) => scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToPage = async (target) => {
    if (location !== '/') {
      await setLocation('/');
    }
    scrollTarget(target);
  };
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light align-items-start footer"
      style={{
        width: '100vw',
        backgroundImage: `url(${FooterImage})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <section className="w-100 container flex-column">
        <img style={{ width: '10%', paddingBottom: '2rem' }} className="mb-4 pointer no-mobile" onClick={() => toggleHome()} src={Logo} alt="ballerwhale" />
        <div className="w-100 d-flex flex-row  align-items-center justify-content-center text-light no-mobile mb-5">
          <div className="d-flex flex-row justify-content-center text-light fl-1">
            <div
              className="fl-1 text-center footer-text"
              onClick={() => (scrollToPage('about'))}
            >
              {strings['menu-element-1']}
            </div>
            <div
              className="fl-1 text-center footer-text"
              onClick={() => (scrollToPage('specs'))}
            >
              {strings['menu-element-2']}
            </div>
            <div
              className="fl-1 text-center footer-text"
              onClick={() => (setLocation('/mint'))}
            >
              {strings['menu-element-3']}
            </div>
            <div
              className="fl-1 text-center footer-text"
              onClick={() => (scrollToPage('roadmap'))}
            >
              {strings['menu-element-4']}
            </div>
            <div
              className="fl-1 text-center footer-text"
              onClick={() => (scrollToPage('benefits'))}
            >
              {strings['menu-element-5']}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center text-light no-desktop">
          <img style={{ maxWidth: '50%' }} className="mb-4" onClick={() => toggleHome()} src={Logo} alt="ballerwhale" />
          <div
            className="fl-1 text-center footer-text"
            onClick={() => (scrollToPage('about'))}
          >
            {strings['menu-element-1']}
          </div>
          <div
            className="fl-1 text-center footer-text"
            onClick={() => (scrollToPage('specs'))}
          >
            {strings['menu-element-2']}
          </div>
          <div
            className="fl-1 text-center footer-text"
            onClick={() => (setLocation('/mint'))}
          >
            {strings['menu-element-3']}
          </div>
          <div
            className="fl-1 text-center footer-text"
            onClick={() => (scrollToPage('roadmap'))}
          >
            {strings['menu-element-4']}
          </div>
          <div
            className="fl-1 text-center footer-text"
            onClick={() => (scrollToPage('benefits'))}
          >
            {strings['menu-element-5']}
          </div>
        </div>
        <div className="w-100 d-flex flex-row justify-content-center mt-2 mb-4">
          <a href="https://www.instagram.com/ballerwhalenft/" target="_blank" rel="noreferrer">
            <button type="button" className="position-relative nav-button mr-4" style={{ width: 50 }}>
              <i style={{ fontSize: '24px' }} className="fa fa-instagram text-light abs-center" />
            </button>
          </a>
          <a href="https://twitter.com/BallerwhaleNFT" target="_blank" rel="noreferrer">
            <button type="button" className="position-relative nav-button ml-2 mr-4" style={{ width: 50 }}>
              <i style={{ fontSize: '24px' }} className="fa fa-twitter text-light abs-center" />
            </button>
          </a>
          <a href="mailto:contact@ballerwhalenft.com" target="_blank" aria-label="Email" rel="noreferrer">
            <button type="button" className="position-relative nav-button ml-2" style={{ width: 50 }}>
              <i style={{ fontSize: '20px', marginTop: '-1px' }} className="fa fa-envelope text-light abs-center" />
            </button>
          </a>
        </div>
        <p className="fl-1 text-center" style={{ color: '#989898' }}>
          BallerWhale
          {' '}
          {new Date().getFullYear()}
          {' '}
          {strings['menu-copyright']}
          {' '}
          <a href="/terms">{strings['menu-terms']}</a>
        </p>
      </section>
    </nav>
  );
};

export default Footer;
