import React from 'react';
import strings from '../../../localization';
import ScrollToTop from '../../../utils/ScrollToTop';

const index = () => (
  <>
    <ScrollToTop />
    <div className="container text-light container-padding" style={{ paddingBottom: '200px' }}>
      <div className="row">
        <div className="col-md-12" style={{ paddingBottom: '50px' }}>
          <h1 className="text-center">{strings['whale-heading']}</h1>
        </div>
        <div className="col-md-6">
          <p>{strings['whale-desc-left']}</p>
        </div>
        <div className="col-md-6">
          <p>{strings['whale-desc-right']}</p>
        </div>
      </div>
      <div className="d-flex justify-content-center" style={{ paddingTop: '5rem' }}>
        <div className="whales-border d-flex justify-content-between">
          <input type="text" placeholder="Enter your email here" />
          <button type="button" className="whales-button text-light">{strings['whale-email']}</button>
        </div>
      </div>
    </div>
  </>
);

export default index;
