/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import { useMetaMask } from 'metamask-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import Web3 from 'web3-eth-contract';
import web3 from 'web3';
import ScrollToTop from '../../../utils/ScrollToTop';

import strings from '../../../localization';
import Minteth from './mintEth';
import Mintpoly from './mintPolygon';

const alias = {
  1: 'ethereum',
  137: 'polygon',
};

const Mint = () => {
  const { account, ethereum, chainId } = useMetaMask(); Web3.setProvider(ethereum);
  const [network, setNetwork] = useState();
  const [location, setLocation] = useLocation();

  const ChangePage = async (id) => {
    setNetwork(id);
    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(id) }],
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Polygon Mainnet',
                chainId: web3.utils.toHex(id),
                nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                rpcUrls: ['https://polygon-rpc.com/'],
              },
            ],
          });
        }
      }
    }
    if (id === 1) {
      setLocation('/mint/ethereum');
    } else {
      setLocation('/mint/polygon');
    }
  };

  return (
    <>
      <ScrollToTop />
      <div className="container d-flex flex-column align-items-center container-padding" style={{ paddingBottom: account ? '200px' : '150px' }}>
        <h1 className="text-light text-center mb-2">{strings['mint-header']}</h1>
        {account ? (
          <>
            <section className="grid grid-2 mb-5" style={{ marginTop: '50px' }}>
              <button
                type="button"
                onClick={() => { ChangePage(1); }}
                className={`btn hero-${network === 1 ? 'emphasis' : 'normal'}`}
              >
                ETHEREUM
              </button>
              <button
                type="button"
                onClick={() => { ChangePage(137); }}
                className={`btn hero-${network === 137 ? 'emphasis' : 'normal'}`}
              >
                POLYGON
              </button>
            </section>
          </>
        ) : (
          <div className="m-0 p-4 please-connect">
            <h1 className="m-0 p-0 text-center">{strings['msg-metamask-please']}</h1>
            <p className="text-center mb-0 mt-2">{strings['msg-metamask-please-desc']}</p>
          </div>
        )}
      </div>
    </>
  );
};
export default Mint;
