/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import { useEffect, useState } from 'react';
import { useMetaMask } from 'metamask-react';
import Web3 from 'web3-eth-contract';
import ReactPaginate from 'react-paginate';
import ScrollToTop from '../../../utils/ScrollToTop';
import IconLeft from '../../../assets/arrow-left.svg';
import IconRight from '../../../assets/arrow-right.svg';

import { PagedGallery } from '../../../components/widgets/galleries';

import abis from '../../../constants/abis.json';
import { contracts } from '../../../constants/blockchain.json';
import { ParseRPCError } from '../../../utils/string';
import strings from '../../../localization';
import collections from '../../../constants/collections.json';

const showInPage = 16;

const Gallery = () => {
  const { account, ethereum } = useMetaMask(); Web3.setProvider(ethereum);
  const {
    main: to, methods: { getMintedTokens, mintPaid },
  } = new Web3(abis.ethPromotion, contracts.promotionEth);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [mintableTokens, setMintableTokens] = useState([]);
  const MAX_SUPPLY = 3000;

  const SetupGallery = () => setTotalPage(Math.ceil(Number(MAX_SUPPLY) / showInPage));
  getMintedTokens().call().then((minted) => {
    setMintableTokens(
      Array.from(Array(Number(MAX_SUPPLY)).keys()).map((i) => i + 1).filter(
        function (e) { return this.indexOf(e) < 0; },
        minted.map((i) => Number(i)),
      ),
    );
  });

  useEffect(() => account && SetupGallery(), [account]);

  return (
    <>
      <ScrollToTop />
      <div className="container container-padding" style={{ paddingBottom: account ? '200px' : '250px' }}>
        <div className="row">
          <h1 className="w-100 text-light text-center">{strings['baby-gallery-header']}</h1>
          <h4
            style={{ fontFamily: 'monospace' }}
            className="w-100 text-light text-center mb-4"
          >
            {strings['baby-gallery-desc']}
          </h4>
        </div>
        {account ? (
          <>
            <PagedGallery
              cid="bafybeiflbe4rcy2mp5tap7kxbhsvqinh2pnvgjlvwo7f2yfgre435q43em"
              provider="testnets.opensea.io/assets/mumbai"
              gateway="ipfs.infura-ipfs.io"
              suffix="png"
              subroute="thumbnails"
              contract={contracts.main}
              indexArray={mintableTokens}
              analyticsParam="bbw_interest"
              steps={showInPage}
              page={currentPage}
              onSelect={(tokenID) => mintPaid(tokenID).estimateGas({
                from: account,
                to,
                value: collections.babyballerwhaleEth.publicSalePriceWei,
              })
                .then((gasEstimate) => mintPaid(tokenID).send({
                  from: account,
                  to,
                  gas: gasEstimate + 100000,
                  value: collections.babyballerwhaleEth.publicSalePriceWei,
                })
                  .then(() => { alert('Successfully Minted!'); })
                  .catch((error) => { alert(ParseRPCError(error)); }))
                .catch(() => { alert(strings['mint-failed']); })
                .finally(() => { SetupGallery(); })}
            />
            <div className="row justify-content-center align-items-center pagination mt-4" style={{ paddingTop: '2rem' }}>
              <ReactPaginate
                breakLabel="..."
                onPageChange={(e) => setCurrentPage(e.selected)}
                marginPagesDisplayed={window.innerWidth > 768 ? 2 : 0}
                pageRangeDisplayed={window.innerWidth > 768 ? 3 : 1}
                pageCount={totalPage}
                nextLabel={(
                  <button className="pagination-button" type="button">
                    {strings['gallery-next']}
                    <img alt="next" src={IconRight} className="ml-2" />
                  </button>
          )}
                previousLabel={(
                  <button className="pagination-button" type="button">
                    <img alt="back" src={IconLeft} className="mr-2" />
                    {strings['gallery-back']}
                  </button>
          )}
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        ) : (
          <div className="abs-center m-0 p-4 please-connect">
            <h1 className="m-0 p-0 text-center">{strings['msg-metamask-please']}</h1>
            <p className="text-center mb-0 mt-2">{strings['msg-metamask-please-desc']}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
