/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from 'react-hook-form';

const MaxSupply = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm();
  return (
    <form className="card p-4 mb-4" onSubmit={handleSubmit(onSubmit)}>
      <h4>Contract Max Supply</h4>
      <div className="form-group">
        <label>Supply Number</label>
        <input type="text" className="form-control" placeholder="1000" {...register('supply')} />
      </div>
      <button type="submit" className="btn btn-primary">Change</button>
    </form>
  );
};

export default MaxSupply;
