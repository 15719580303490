import { motion } from 'framer-motion/dist/framer-motion';
import strings from '../../../localization';

const About = () => (
  <section
    id="about"
    className="text-light"
    style={{
      overflowX: 'hidden',
      background: 'radial-gradient(72.45% 74.23% at 8.16% 5.45%, #FEEFEC 0%, #21B1E1 39.88%, rgba(32,52,154,0.1) 100%)',
    }}
  >
    <main className="row offset-1 col-sm-10 h-100 py-5">
      <section className="col-md-6">
        <div>
          {window.innerWidth > 768 ? (
            <motion.img
              initial={{ x: 0, y: 0, rotate: 20 }}
              animate={{
                x: [1, 5, -1, -5, 3],
                y: [2, -2, 4, -4, -2],
                rotate: [2, -2, 1, -1, 1],
              }}
              transition={{ duration: 10, yoyo: Infinity }}
              className="about-bubble"
              src="assets/about/bubble.png"
              alt="bubble"
            />
          ) : (
            <img
              initial={{ x: 0, y: 0, rotate: 20 }}
              animate={{
                x: [1, 5, -1, -5, 3],
                y: [2, -2, 4, -4, -2],
                rotate: [2, -2, 1, -1, 1],
              }}
              transition={{ duration: 10, yoyo: Infinity }}
              className="about-bubble"
              src="assets/about/bubble.png"
              alt="bubble"
            />
          )}
        </div>
      </section>
      <section className="col-md-6 d-flex justify-content-center flex-column" style={{ width: '80%' }}>
        <h1 className="header mb-4" style={{ overflow: 'hidden' }}>{strings['about-title']}</h1>
        <h2 className="position-relative about-title">
          <img
            className="abs-center no-mobile"
            src="assets/about/about-icon-1.png"
            alt="btc"
            style={{
              left: '-3rem',
            }}
          />
          {strings['about-card-title']}
        </h2>
        <p>{strings['about-card-desc']}</p>
        <h2 className="position-relative about-title mt-4">
          <img
            className="abs-center no-mobile"
            src="assets/about/about-icon-2.png"
            alt="btc"
            style={{
              left: '-3rem',
            }}
          />
          {strings['about-what-title']}
        </h2>
        <p>{strings['about-what-desc']}</p>
      </section>
    </main>
  </section>
);

export default About;
