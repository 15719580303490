/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */

import { useState, useEffect } from 'react';
import { useMetaMask } from 'metamask-react';
import { Link, useLocation } from 'wouter';
import { animateScroll as scroll } from 'react-scroll';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Logo from '../../assets/navbar-icon.png';
import WalletIcon from '../../assets/icon-wallet.svg';

import admins from '../../constants/admins.json';
import strings from '../../localization';
import Menu from './Menu';

const Navbar = () => {
  const { account, status, connect } = useMetaMask();
  const [isOpen, setIsOpen] = useState(false);
  const [, setLocation] = useLocation();
  const [scrollNav, setScrollNav] = useState(false);
  const analytics = getAnalytics();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleHome = () => {
    setLocation('/');
    scroll.scrollToTop();
  };

  const changeNav = () => {
    if (window.scrollY >= 100) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  return (
    <nav
      role="navigation"
      className="navbar fixed-top navbar-expand-lg"
      style={{ zIndex: 998 }}
    >
      <Menu toggle={toggle} isOpen={isOpen} />
      <button type="button" className="position-relative nav-button" style={{ width: 50 }} onClick={toggle}>
        <img alt="menu" src="../assets/navbar/icon-menu.svg" className="abs-center" width={20} />
      </button>
      <ul style={{ display: 'flex', flex: 1 }}>
        {/* <li className="nav-item nav-link">Home</li>
        <li className="nav-item nav-link">About</li>
        <li className="nav-item nav-link">Mint</li> */}
      </ul>
      <img className={`pointer logo logo-${scrollNav ? 'invisible' : 'visible'}`} onClick={() => toggleHome()} alt="ballerwhale-nav-icon" src={Logo} height={128} />
      <button
        type="button"
        className="d-flex align-items-center nav-button"
        onClick={() => {
          logEvent(analytics, 'wallet_connected');
          connect();
        }}
      >
        <img alt="menu" src={WalletIcon} width={20} />
        <span className="ml-2 no-mobile">
          {strings['msg-metamask'][status]}
        </span>
      </button>
      {admins.includes(account) && (
        <Link to="/admin">
          <button type="button" className="my-2 my-sm-0 ml-2 no-mobile nav-button" onClick={toggle}>
            <i className="fa fa-warning mr-2" />
            Administrate
            <i className="fa fa-warning ml-2" />
          </button>
        </Link>
      )}
    </nav>
  );
};

export default Navbar;
