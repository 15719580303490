/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from 'react-hook-form';

const MintTo = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm();
  return (
    <form className="card p-4 mb-4" onSubmit={handleSubmit(onSubmit)}>
      <h4>Mint to Wallet Address</h4>
      <div className="form-group">
        <label>Wallet address</label>
        <input type="text" className="form-control" placeholder="Enter email" {...register('address')} />
        <small className="form-text text-muted">Please be sure that the address is Polygon Wallet address.</small>
      </div>
      <div className="form-group">
        <label>Amount</label>
        <input type="number" className="form-control" placeholder="Password" {...register('amount')} defaultValue={1} />
      </div>
      <button type="submit" className="btn btn-primary">Mint</button>
    </form>
  );
};

export default MintTo;
