/* eslint-disable no-unused-vars */
import Hero from './Hero';
import About from './About';
import Specs from './Specs';
import Mentorship from './Mentorship';
import Roadmap from './Roadmap';
import MemberBenefits from './MemberBenefits';
import ScrollToTop from '../../../utils/ScrollToTop';

const Landing = () => (
  <main>
    <ScrollToTop />
    <Hero />
    <img className="top-wave no-mobile" src="assets/divider/divider-top.svg" alt="divider" />
    <div className="hero-mobile-space" />
    <About />
    <img style={{ width: '100vw', padding: '5rem 0' }} src="assets/divider/divider-2.svg " alt="divider" />
    <Specs />
    <img style={{ width: '100vw', padding: '5rem 0' }} src="assets/divider/divider-2.svg " alt="divider" />
    <Mentorship />
    <img style={{ width: '100vw', padding: '5rem 0' }} src="assets/divider/divider-2.svg " alt="divider" />
    <Roadmap />
    <img style={{ width: '100vw', padding: '5rem 0' }} src="assets/divider/divider-2.svg " alt="divider" />
    <MemberBenefits />
  </main>
);

export default Landing;
