const ParseRPCError = (error) => error
  .toString()
  .split(/\r?\n/)
  .find((l) => l.includes('message'))
  .split('": "')[1]
  .split('",')[0]
  .split(': ')[1];

export {
  ParseRPCError,
};
