/* eslint-disable jsx-a11y/label-has-associated-control */

const Withdraw = ({ onSubmit }) => (
  <form className="card p-4 mb-4">
    <h4>Withdraw ?</h4>
    <button type="button" className="btn btn-primary" onClick={onSubmit}>Withdraw</button>
  </form>
);

export default Withdraw;
