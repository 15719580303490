import { Link } from 'wouter';
import strings from '../../../localization';

const Hero = () => (
  <main
    className="full-page d-flex flex-column align-items-center hero-main"
  >
    <h1 className="display-4 font-weight-bold text-center hero-text">{strings['hero-title']}</h1>
    <p className="text-align-center hero-desc">{strings['hero-subtitle']}</p>
    <section className="grid grid-2">
      <Link href="/mint">
        <button type="button" className="btn hero-emphasis">{strings['hero-action-left']}</button>
      </Link>
      {/* <Link href="/galleryballer">
        <button type="button" className="btn hero-normal position-relative">
          {strings['hero-action-right']}
          <img className="hero-action-right-gift" alt="hero-gift" src="assets/hero/hero-gift.svg" />
        </button>
      </Link> */}
      <a href="/galleryballer">
        <button type="button" className="btn hero-normal position-relative">
          {strings['hero-action-right']}
          <img className="hero-action-right-gift" alt="hero-gift" src="assets/hero/hero-gift.svg" />
        </button>
      </a>
    </section>
  </main>
);

export default Hero;
