import strings from '../../../localization';

const MemberBenefits = () => (
  <div id="benefits" className="container mb-5 member-benefits">
    <h1 className="text-center text-light mb-5">{strings['benefits-title']}</h1>
    <section className="grid grid-2">
      <div
        style={{ backgroundImage: 'url(assets/memberBenefits/member-bg-1.png)' }}
        className="member-benefits-container"
      >
        <img src="assets/memberBenefits/member-image-1.png" alt="member1" />
        <p className="text-light">
          {strings['benefits-text-1']}
        </p>
      </div>
      <div
        style={{ backgroundImage: 'url(assets/memberBenefits/member-bg-2.png)' }}
        className="member-benefits-container"
      >
        <img src="assets/memberBenefits/member-image-2.png" alt="member2" />
        <p className="text-light">
          {strings['benefits-text-2']}
        </p>
      </div>
      <div
        style={{ backgroundImage: 'url(assets/memberBenefits/member-bg-3.png)' }}
        className="member-benefits-container"
      >
        <img src="assets/memberBenefits/member-image-3.png" alt="member3" />
        <p className="text-light">
          {strings['benefits-text-3']}
        </p>
      </div>
      <div style={{ backgroundImage: 'url(assets/memberBenefits/member-bg-4.png)' }} className="member-benefits-container">
        <img src="assets/memberBenefits/member-image-4.png" alt="member4" />
        <p className="text-light">
          {strings['benefits-text-4']}
        </p>
      </div>
    </section>
  </div>
);

export default MemberBenefits;
