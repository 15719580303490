import Localization from 'react-localization';
import { getLang } from '../libs/storage';

import en from './en.json';
import fr from './fr.json';
import esp from './esp.json';

const localization = new Localization({ en, fr, esp });
localization.setLanguage(getLang() || 'en');

export default localization;
